import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const NotFoundStyle = styled.div`
  min-height: 600px;
  --red: ${({ theme: { palette } }) => palette.secondary.main};
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  .content {
    margin-bottom: 50px;
    text-align: center;
    a {
      margin-top: 20px;
      border: 2px solid var(--red);
      background: var(--red);
      color: white;
      border-radius: 10px;
      padding: 10px 20px;
      display: inline-block;
      transition: box-shadow 250ms ease-in-out;
      &:hover {
        transition: box-shadow 250ms ease-in-out;
        box-shadow: 1px 1px 15px
          ${({ theme: { palette } }) => palette.primary[500]};
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: row-reverse;
    gap: 20px;
  }
`;

export default function NotFoundPage() {
  return (
    <NotFoundStyle>
      <StaticImage
        width={900}
        height={600}
        layout="constrained"
        src="../assets/static-images/404.jpg"
        alt="page not found on iconic taste usa"
      ></StaticImage>
      <div className="content">
        <h1>Whooops!</h1>
        <p>We could not find the page you are looking for</p>
        <Link to="/">GO BACK →</Link>
      </div>
    </NotFoundStyle>
  );
}
